import React from "react"
import { Grid } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import MP3Player from "./MP3Player"

const query = graphql`
  query getInterviews {
    allContentfulInterview {
      interviews: nodes {
        id
        title
        audio {
          file {
            url
          }
        }
      }
    }
  }
`

const Interviews = () => {
  const {
    allContentfulInterview: { interviews },
  } = useStaticQuery(query)
  return (
    <Grid container spacing={5}>
      {interviews.map(interview => (
        <Grid item xs={12} sm={6} md={4} key={interview.id}>
          <MP3Player title={interview.title} src={interview.audio.file.url} />
        </Grid>
      ))}
    </Grid>
  )
}

export default Interviews
