import React, { useState } from "react"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    padding: "1rem",
    textAlign: "center",
  },
  audioPlayer: {
    background: "unset !important",
    boxShadow: "unset !important",
  },
  isPlaying: {
    background: theme.palette.primary.main,
    "& button": {
      color: theme.palette.common.black,
    },
  },
}))

const MP3Player = ({ src, title }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const classes = useStyles()

  const handlePlay = () => {
    setIsPlaying(true)
  }

  const handleStop = () => {
    setIsPlaying(false)
  }
  return (
    <div className={`${classes.container} ${isPlaying && classes.isPlaying}`}>
      <Typography variant="h5" component="h5" classes={{ root: classes.title }}>
        {title}
      </Typography>
      <AudioPlayer
        src={src}
        className={`${classes.audioPlayer} ${isPlaying && classes.isPlaying}`}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        onPlay={handlePlay}
        onPause={handleStop}
        onEnded={handleStop}
      />
    </div>
  )
}

export default MP3Player
