import React from "react"
import { Grid } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import MP3Player from "./MP3Player"

const query = graphql`
  query getDubplate {
    allContentfulDubplate {
      dubplates: nodes {
        id
        title
        audio {
          file {
            url
          }
        }
      }
    }
  }
`

const Dubplates = () => {
  const {
    allContentfulDubplate: { dubplates },
  } = useStaticQuery(query)
  return (
    <Grid container spacing={5}>
      {dubplates.map(dubplate => (
        <Grid item xs={12} sm={6} md={4} key={dubplate.id}>
          <MP3Player title={dubplate.title} src={dubplate.audio.file.url} />
        </Grid>
      ))}
    </Grid>
  )
}

export default Dubplates
