import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Tabs, Tab, useTheme, useMediaQuery } from "@material-ui/core"
import TabPanel from "./TabPanel"
import ImageGallery from "./ImageGallery"
import Interviews from "./Interviews"
import Jingles from "./Jingles"
import Dubplates from "./Dubplates"

const TABS = [
  { label: "Bilder" },
  { label: "Interviews" },
  { label: "Jingles" },
  { label: "Dubplates" },
]

const useStyles = makeStyles(theme => ({
  tab: {
    fontSize: "1.2rem",
    opacity: "0.4",
    transition: "opacity 0.3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
      "&:hover": {
        opacity: "1",
      },
    },
  },
  tabContainer: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  },
}))

const a11yProps = index => {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  }
}

const TabNav = () => {
  const [value, setValue] = useState(0)
  const classes = useStyles()
  const theme = useTheme()
  const upMD = useMediaQuery(theme.breakpoints.up("md"))

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div>
      <Tabs
        classes={{ root: classes.tabContainer }}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons={upMD ? "off" : "on"}
        value={value}
        onChange={handleChange}
        aria-label="media nav tabs"
      >
        {TABS.map((tab, index) => (
          <Tab
            key={index}
            classes={{ root: classes.tab }}
            disableFocusRipple
            disableRipple
            component="a"
            onClick={e => e.preventDefault()}
            label={tab.label}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      <TabPanel value={value} index={0}>
        <ImageGallery />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Interviews />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Jingles />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Dubplates />
      </TabPanel>
    </div>
  )
}

export default TabNav
