import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  gridItem: {
    height: "25rem",
    position: "relative",
  },
  mainWrapper: {
    width: "100%",
    height: "100%",
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    transition: "filter 0.3s ease-in-out",
  },
  image: {
    height: "100%",
    zIndex: -1,
  },
  overlayFilter: {
    filter: "blur(4px) brightness(0.3)",
  },
  description: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "1.2rem",
    textTransform: "capitalize",
  },
  mobileDescription: {
    fontSize: "1.2rem",
    textAlign: "center",
  },
}))

const query = graphql`
  query getImageGallery {
    gallery: allContentfulImageGallery {
      nodes {
        images {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
          id
        }
      }
    }
  }
`

const GalleryImage = ({ src, title }) => {
  const [overlay, setOverlay] = useState(false)
  const theme = useTheme()
  const downSM = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles()

  const openOverlay = () => {
    setOverlay(true)
  }
  const closeOverlay = () => {
    setOverlay(false)
  }
  return (
    <>
      {downSM ? (
        <>
          <div className={classes.imageWrapper}>
            <Image fluid={src} className={classes.image} />
          </div>
          <Typography
            variant="body1"
            component="p"
            classes={{ root: classes.mobileDescription }}
          >
            {title}
          </Typography>
        </>
      ) : (
        <div
          role="button"
          tabIndex={0}
          onMouseEnter={openOverlay}
          onMouseLeave={closeOverlay}
          className={classes.mainWrapper}
        >
          <div
            className={`${classes.imageWrapper} ${
              overlay && classes.overlayFilter
            }`}
          >
            <Image fluid={src} className={classes.image} />
          </div>
          {overlay && (
            <Typography
              variant="body1"
              component="p"
              classes={{ root: classes.description }}
            >
              {title}
            </Typography>
          )}
        </div>
      )}
    </>
  )
}

const ImageGallery = () => {
  const classes = useStyles()
  const theme = useTheme()
  const downXS = useMediaQuery(theme.breakpoints.down("sm"))
  const {
    gallery: { nodes: images },
  } = useStaticQuery(query)
  const galleryImages = images?.[0]?.images

  return (
    <Grid container spacing={downXS ? 8 : 5}>
      {galleryImages?.map(img => (
        <Grid
          classes={{ root: classes.gridItem }}
          item
          xs={12}
          sm={6}
          md={4}
          key={img.id}
        >
          <GalleryImage
            src={img.fluid}
            title={img.title}
            style={{ height: "100%" }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default ImageGallery
