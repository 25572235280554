import React from "react"
import { Grid } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import MP3Player from "./MP3Player"

const query = graphql`
  query getJingles {
    allContentfulJingle {
      jingles: nodes {
        id
        title
        audio {
          file {
            url
          }
        }
      }
    }
  }
`

const Jingles = () => {
  const {
    allContentfulJingle: { jingles },
  } = useStaticQuery(query)
  return (
    <Grid container spacing={5}>
      {jingles.map(jingle => (
        <Grid item xs={12} sm={6} md={4} key={jingle.id}>
          <MP3Player title={jingle.title} src={jingle.audio.file.url} />
        </Grid>
      ))}
    </Grid>
  )
}

export default Jingles
