import React from "react"
import { graphql } from "gatsby"
import MainLayout from "../components/Layout/MainLayout"
import SEO from "../components/Seo"
import Section from "../components/Layout/Section"
import TabNav from "../components/Media/TabNav"

export const data = graphql`
  query getMediaHero {
    hero: contentfulHeroImages {
      media {
        id
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

const MediaPage = ({ location, data }) => {
  const {
    hero: {
      media: { fluid },
    },
  } = data
  return (
    <MainLayout location={location} imageSrc={fluid} title="Media">
      <SEO title="Media" />
      <Section>
        <TabNav />
      </Section>
    </MainLayout>
  )
}

export default MediaPage
