import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "5rem 0",
  },
}))

const TabPanel = ({ children, value, index }) => {
  const classes = useStyles()
  return (
    <div
      className={classes.root}
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      {value === index && children}
    </div>
  )
}

export default TabPanel
